//
// forms.scss
//


.form-control,
.form-select {
    box-shadow: $input-box-shadow;
}


// Form-control light

// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: $input-height;
    }
}

select.form-control-sm {
    &:not([size]):not([multiple]) {
        height: $input-height-sm;
    }
}

// Form-control light
.form-control-light {
    background-color: var(--#{$prefix}light)  !important;
    border-color: var(--#{$prefix}light)  !important;
}


// Comment box
.comment-area-box {
    .form-control {
        border-color: var(--#{$prefix}border-color);
        border-radius: $input-border-radius $input-border-radius 0 0;
    }

    .comment-area-btn {
        background-color: var(--#{$prefix}light);
        padding: 10px;
        border: 1px solid var(--#{$prefix}border-color);
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}



// Search box
.search-bar {
    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: $input-height;
        left: 13px;
        top: -2px;
        color: $text-muted;
    }
}

// Show/Hide Password
.password-eye {
    cursor: pointer;

    &:before {
        font-family: "Material Design Icons";
        content: "\F06D0";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\F06D1";
        }
    }
}

.filter-search-form {
    .selectForm__inner {
      box-shadow: none !important;
      border: 0;
      font-size: 15px;
      height: 60px;
      padding: 16px 6px 15px 45px !important;
      border-radius: 0;
    }
  }

  .registration-form {
    position: relative;
  
    .submit-btn {
      border-radius: 8px;
    }
  
    .form-control,
    .choices[data-type*=select-one] .choices__inner {
      border-radius: 8px;
    }
  }

  .filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  box-shadow: none !important;
  border: 0;
  font-size: 15px;
  height: 60px;
  padding: 16px 6px 15px 45px;
  border-radius: 0;
}

.filter-search-form {
    .selectForm__inner {
      box-shadow: none !important;
      border: 0;
      font-size: 15px;
      height: 60px;
      padding: 16px 6px 15px 45px !important;
      border-radius: 0;
    }
  }