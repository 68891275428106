// 
// profile.scss
//

.post-user-comment-box {
    background-color: var(--#{$prefix}light);
    margin: 0 -.75rem;
    padding: 1rem;
    margin-top: 20px;
}
