// 
// sweetalert.scss
//

// Popup
.swal2-popup {
	background-color: $card-bg !important;
	@include border-radius($border-radius);

	.swal2-title {
		font-weight: 500;
		font-size: 1.3rem;
		color: var(--#{$prefix}dark);
	}

	.swal2-html-container,
	.swal2-content {
		font-weight: normal;
		font-size: 1.1rem;
		margin-top: 1.5rem;
		color: var(--#{$prefix}heading-color);
	}

	.swal2-footer {
		border-top-color: var(--#{$prefix}border-color) !important;
	}

	.btn {
		margin: 15px 5px 0;
	}

	.swal2-styled {
		&:focus {
			box-shadow: none;
		}
	}

	.swal2-actions {
		margin: 1.5rem auto 1rem auto;
	}
}

.swal2-modal {
    font-family: $font-family-base;
    box-shadow: 0 10px 33px rgba($black, .1);

    .swal2-title {
        font-size: 24px;
    }

    .swal2-content {
        font-size: 16px;
    }

    .swal2-spacer {
        margin: 10px 0;
    }

    .swal2-file,
    .swal2-input,
    .swal2-textarea {
        border: 2px solid var(--#{$prefix}border-color);
        font-size: 16px;
        box-shadow: none;
    }

    .swal2-confirm {
        background-color: $primary !important;
        font-size: $font-size-base !important;
    }

    .swal2-cancel.btn-cancel {
        background-color: $danger !important;
        font-size: $font-size-base;
    }

    .swal2-confirm, .swal2-cancel {
        margin: .3125em;
        padding: .625em 2em;
        font-weight: 500;
        box-shadow: none;
        font-size: $btn-font-size !important;
        &:focus {
            box-shadow: none !important;
        }
    }

    .swal2-file:focus,
    .swal2-input:focus,
    .swal2-textarea:focus {
        outline: 0;
        border: 2px solid $primary;
    }
}

.swal2-icon {
    &.swal2-question {
        color: $primary !important;
        border-color: $primary !important;
    }

    &.swal2-success {
        border-color: $success;
    
        .line,
        [class^=swal2-success-line][class$=long],
        [class^=swal2-success-line] {
            background-color: $success !important;
        }
    
        .placeholder,
        .swal2-success-ring {
            border-color: $success !important;
        }
    }

    &.swal2-warning {
        color: $warning !important;
        border-color: $warning !important;
    }
    
    &.swal2-error {
        border-color: $danger !important;
    
        .line {
            background-color: $danger !important;
        }
    }

    &.swal2-info {
        border-color: $info;
        color:  $info;
    }
}

.swal2-actions {
    margin: 1.6em auto 0 !important;
}

.swal2-container {
    &.swal2-backdrop-show, &.swal2-noanimation {
        background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity) !important;
    }
}

